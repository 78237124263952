.select {
  z-index: 8;
  position: relative;
  padding: 0 20px;
  width: 300px;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 16px;
  font-weight: normal;
  line-height: 25.6px;
  color: var(--color-black);
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }
  &:invalid {
    color: var(--color-gray);
  }
  &:focus {
    outline: 1px solid var(--color-turquoise);
  }

  &__wrapper {
    z-index: 10;
    position: relative;
    width: fit-content;
    border-radius: 5px;
    background-color: var(--color-lightgray);

    &:before, &:after {
      content: '';
      z-index: 5;
      position: absolute;
      top: 50%;
      width: 12px;
      height: 2px;
      background-color: var(--color-black);
    }

    &:before {
      right: 28px;
      transform: rotate(45deg);
    }

    &:after {
      right: 20px;
      transform: rotate(-45deg);
    }
  }
}
