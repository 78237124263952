.buttonDownload {
  cursor: pointer;
  display: flex;
  background-color: transparent;
  border: none;
  outline: none;

  &__text {
    margin-left: 10px;
    color: var(--color-gray);
  }
}