.users {
  padding: 60px 0;

  &__title {
    color: var(--color-darkgray);
  }
}

.resultOfChecking {
  margin-top: 60px;

  &__content {
    margin-top: 30px;
  }
}
.addUser {
 display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 20px;

  &__wraper {
    padding-top: 15px;
  }

  &__inputWrap {
    display: flex;
    flex-direction: column;
    row-gap: 10px
    }
  &__checkBoxWrap {

  }
}
.errorWrap {
  margin-top: 20px;
  border: 1px solid red;
  border-radius: 20px;
  padding: 10px;
  background: #ffdddd;
  text-align: center;
}
.table {

  &__titles {
    padding-left: 30px;
    height: 50px;
    display: grid;
    grid-template-columns: 50px repeat(3,1fr);
    background-color: var(--color-gray6);
    align-items: center;
  }

  &__row {
    padding-left: 30px;
    min-height: 50px;
    display: grid;
    grid-template-columns: 50px repeat(3,1fr) 100px;
    background-color: var(--color-white);
    align-items: center;

    &_dark {
      background-color: var(--color-lightgray);
    }
  }

  &__col {
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-black);
    //text-align: center;

    &_link:hover {
      color: var(--color-darkgray);
    }
  }

  &__privilege {
    font-size: 13px;
    font-weight: normal;
  }
  &__actions {
    display: flex;
    justify-content: end;
    column-gap: 5px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
  &__action {
    cursor: pointer;
  }
}
