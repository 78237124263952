.login {
  padding: 173px 0;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    color: var(--color-darkgray);
  }
}

.form {
  margin-top: 30px;
  padding: 50px;
  width: 440px;
  border: 1px solid var(--color-gray);
  border-radius: 20px;

  &__item {
    margin-bottom: 30px;
    &:last-child {
      margin: 0;
    }
  }

  button {
    margin-top: 20px;
  }
}