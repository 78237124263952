.loader {
  display: flex;
  align-items: center;

  img{
    animation: rotate 3s infinite linear;
  }

  &__text {
    margin-left: 10px;
    color: var(--color-orange);
  }
}

.loaded {
  display: flex;
  align-items: center;

  &__text {
    margin-left: 10px;
    color: var(--color-turquoise);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}