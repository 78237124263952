.checkbox {
  width: 15px;
  height: 15px;
  margin-top: 0;
  margin-bottom: 0;

  &__wrapper {
    display: grid;
    grid-template-columns: 17px auto;
    align-items: center;
    column-gap: 5px;
    font-size: 13px;
    &_text {
      padding: 3px 0;
    }
  }
}