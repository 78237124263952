.header {
  height: 100px;
  
  &__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray);
  }

  &__logo {
    color: var(--color-turquoise);
  }
}