.editUser {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 20px;

  &__wraper {
    padding-top: 15px;
  }

  &__inputWrap {
    display: flex;
    flex-direction: column;
    row-gap: 10px
  }
  &__hint {
    font-style: italic;
    font-size: 12px;
    color: #2f2d2d;
  }
}
.errorWrap {
  margin-top: 20px;
  border: 1px solid red;
  border-radius: 20px;
  padding: 10px;
  background: #ffdddd;
  text-align: center;
}