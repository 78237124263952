.button {
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: var(--color-white);
  background-color: var(--color-turquoise);
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: #01ADA0;
  }

  &:active {
    background-color: #02968A;
  }

  &:disabled {
    opacity: .5;
  }
}