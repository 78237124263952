.home {
  padding: 60px 0;

  &__title {
    color: var(--color-darkgray);
  }
}
.filters {
  display: flex;
  align-items: center;
  gap: 10px;
  &_wrap {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    gap: 30px;
  }

  &__cell {
    display: flex;
    flex-direction: column;
  }
  &__input {
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    background-color: var(--color-lightgray);
    font-size: 16px;
    font-weight: normal;
    line-height: 25.6px;
    color: var(--color-black);
  }
}
.resultOfChecking {
  margin-top: 60px;

  &__content {
    margin-top: 30px;
  }
}

.table {

  &__titles {
    padding-left: 30px;
    height: 50px;
    display: grid;
    grid-template-columns: 17% 17% 17% 17% 17% 15%;
    background-color: var(--color-gray6);
    align-items: center;
  }

  &__row {
    padding-left: 30px;
    min-height: 50px;
    display: grid;
    grid-template-columns: 17% 17% 17% 17% 17% 15%;
    background-color: var(--color-white);
    align-items: center;

    &_dark {
      background-color: var(--color-lightgray);
    }
  }

  &__col {
    padding-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-black);
    text-align: center;

    &_link:hover {
      color: var(--color-darkgray);
    }
  }
}
.button {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  background-color: transparent;
  border: none;
  outline: none;

  &.disabled {
    opacity: .3;
    cursor: default!important;
  }
}
