@font-face {
  font-family: Littera-Plain;
  src: url('../fonts/LitteraPlainBold.eot');
  src: local('Littera Plain Bold'), local('LitteraPlainBold'),
  url('../fonts/LitteraPlainBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/LitteraPlainBold.woff') format('woff'),
  url('../fonts/LitteraPlainBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Littera-Plain;
  src: url('../fonts/LitteraPlainRegular.eot');
  src: local('Littera Plain Regular'), local('LitteraPlainRegular'),
  url('../fonts/LitteraPlainRegular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/LitteraPlainRegular.woff') format('woff'),
  url('../fonts/LitteraPlainRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Littera-Plain;
  src: url('../fonts/LitteraPlainMedium.eot');
  src: local('Littera Plain Medium'), local('LitteraPlainMedium'),
  url('../fonts/LitteraPlainMedium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/LitteraPlainMedium.woff') format('woff'),
  url('../fonts/LitteraPlainMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-lightgray: #F8F8F8;
  --color-gray: #6F6F6F;
  --color-gray6: #F2F2F2;
  --color-darkgray: #333333;
  --color-turquoise: #00A89B;
  --color-orange: #F47536;
  --color-lightred: #FFF2F2;
  --color-red: #EB5757;
}

body {
  margin: 0;
  padding: 0;
  font-family: Littera-Plain, sans-serif;

  a {
    text-decoration: none;
  }

  ul, li {
    list-style: none;
  }
 .d-flex {
   display: flex;
 }
 .space-between {
   justify-content: space-between;
   align-items: center;
 }
 .align-items-center {
   align-items: center;
 }
  .align-items-end {
    align-items: end;
  }
}
