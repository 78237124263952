.requests {
  display: flex;
  justify-content: space-between;

  &__wrapper {
    margin-top: 30px;
  }

  &__input {
    width: 1010px;
    background-color: var(--color-gray6);
    border-radius: 5px;
  }

  &__list {
    padding: 5px 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    color: var(--color-gray);
  }

  &__buttons {
    width: 250px;
    display: flex;
    flex-direction: column;
  }
}

.button {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  background-color: transparent;
  border: none;
  outline: none;

  &.disabled {
    opacity: .3;
  }
}