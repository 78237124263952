.input {
  padding-left: 20px;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  font-size: 16px;
  font-weight: normal;
  line-height: 19.4px;
  color: var(--color-black);
  outline: none;

  &:focus {
    outline: 1px solid var(--color-turquoise);
  }

  &::placeholder {
    color: var(--color-gray);
  }

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    background-color: var(--color-lightgray);
    outline: none;
    border-radius: 5px;
  }

  &__button {
    position: absolute;
    top: 3px;
    right: 2px;
    width: 45px;
    height: calc(100% - 4px);
  }
}