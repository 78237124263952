.buttonClose {
  padding: 0;
  cursor: inherit;
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;

  &__frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
