.modal {

}
.modalBack {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modalWindow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  min-width: 300px;
  border-radius: 10px;
  padding: 15px;

  &__title {
    min-height: 30px;
    padding-right: 30px;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    height: 15px;
    padding: 5px;
    cursor: pointer;
  }
}