.buttonOut {
  cursor: pointer;
  outline: none;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;

  &__text {
    color: var(--color-gray);
  }

  &__icon {
    margin-left: 15px;
    height: 20px;
    color: var(--color-turquoise);

    &:hover {
      color: #01ADA0;
    }

    &:active {
      color: #02968A;
    }
  }
}