[data-type="pagination"] {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  .paginationContainer {
    display: flex;
    list-style-type: none;
    padding: 0;

    .paginationItem {
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      min-width: 32px;
      border-radius: 5px;
      position: relative;

      &__dots {
        height: 32px;
        text-align: center;
        margin: auto 4px;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        min-width: 32px;
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: var(--color-turquoise);
        cursor: pointer;
        opacity: 0.5;
        color: white;
      }

      &__selected {
        height: 32px;
        min-width: 32px;
        text-align: center;
        margin: auto 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        background-color: var(--color-turquoise);
        color: white;
        border-radius: 5px;
      }

    }

    .paginationArrow {
      position: relative;
      min-width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      &__left {
        position: relative;
        top: -4px;
        transform: rotate(-135deg) translate(-50%);
        &::before {
          position: relative;
          content: '';
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }
      }

      &__right {
        position: relative;
        top: 0;
        transform: rotate(45deg);
        &::before {
          position: relative;
          content: '';
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }
      }
    }

    .disabled {
      opacity: 0;
      cursor: default!important;
    }
  }

  .paginationPageSize {
    position: absolute;
    right: 0;
    outline:none;
    border: none;
    border-radius: 5px;
    background-color: var(--color-lightgray);
    padding: 4px 8px 4px 4px;
  }
}