.requestAddress {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  &__address {
    width: 830px;
  }

  &__region {
    width: 430px;
  }
  &__input {
    display: flex;
  }
}