.requestSearchItem {
  z-index: 10;
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: flex-start;
  color: var(--color-gray);

  button {
    margin-left: 5px;
  }
}